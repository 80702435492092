import React, {useContext, useEffect, useState, useCallback, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import Styled from '@oracle-cx-commerce/react-components/styled';
import {StoreContext} from '@oracle-cx-commerce/react-ui/contexts';
import {useSelector, connect} from '@oracle-cx-commerce/react-components/provider';
import {getRecommendations, getRecommendationsSet, isMobile} from '@oracle-cx-commerce/commerce-utils/selector';
import {isEmptyObject} from '@oracle-cx-commerce/utils/generic';
import {Box, Flex} from '../../../../ltd-ui';
import {Splide, SplideSlide, SplideTrack} from '@splidejs/react-splide';
import {OUT_OF_STOCK} from '@oracle-cx-commerce/commerce-utils/constants/cart';
import ProductItem from './components/product-item';
import css from './styles.css';
import {getComponentData} from './selectors';

import Skeleton from 'react-loading-skeleton';
import {useIsInViewport} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/common/ltd-lazy-loading-container';
import {handleTealiumViewItemList} from '../../data-tagging/ltd-product-recommendation/ltd-product-recommendation';

const LTDProductRecommendationsCarousel = props => {
  const {widgetId, headingProductRecommendations, displayCTAButton, configItemsPerSlideDesktop} = props;
  const {products, skus, skusPrices, skuInventoryItems} = props;
  const trimmedHeadingProductRecommendations = headingProductRecommendations.trim();

  const recommendations = useSelector(getRecommendations, {widgetId});
  const {recSetId} = useSelector(getRecommendationsSet, {widgetId});
  const mobile = useSelector(isMobile);
  const store = useContext(StoreContext);
  const {action} = useContext(StoreContext);

  const [skuIds, setSkuIds] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [slides, setSlides] = useState([]);
  const [lastRecommendation, setLastRecommendation] = useState('');
  const [lastSkus, setLastSkus] = useState('');
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [refreshSlide, setRefreshSlide] = useState(false);
  const [slideSettings, setSlideSettings] = useState({});
  const [skusInSlides, setSkusInSlides] = useState([]);
  const [isClient, setIsClient] = useState(false);

  const splideRef = useRef();

  const elementRef = useRef();
  const displayWidget = useIsInViewport(elementRef);

  const fetchProducts = useCallback(
    async stringified => {
      setSkuIds([]);
      setLastRecommendation(stringified);

      const filteredRecommendations = recommendations.filter(productId => isEmptyObject(products?.[productId]));

      if (filteredRecommendations.length > 0) {
        const payload = {
          productIds: filteredRecommendations,
          filterKey: 'productRecommendations',
          continueOnMissingProduct: true
        };

        action('listProducts', payload).catch(() => {
          setShowSkeleton(false);
        });

        await action('getStockStatus', {
          products: payload.productIds,
          actualStockStatus: true
        }).catch(() => {
          setShowSkeleton(false);
        });
      }
    },
    [action, products, recommendations]
  );

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowSkeleton(false);
    }, 20000);

    return () => {
      clearTimeout(timeout);
    }
  }, []);

  useEffect(() => {
    if (displayWidget) {
      setShowSkeleton(true);
      const timeout = setTimeout(() => {
        setShowSkeleton(false);
      }, 8000);
  
      return () => {
        clearTimeout(timeout);
      }
    }    
  }, [displayWidget]);

  useEffect(() => {
    if (!displayWidget) return;

    const stringified = recommendations.join(',');

    if (recommendations && recommendations.length > 0 && lastRecommendation !== stringified) {
      fetchProducts(stringified);
    }
    else if(recSetId && recommendations?.length === 0){
      setShowSkeleton(false);
    }
  }, [displayWidget, fetchProducts, lastRecommendation, recommendations]);

  useEffect(() => {
    if (!isEmptyObject(products) && recommendations.length) {
      const skuIds = [];
      const productIds = [];

      recommendations
        .map(productId => products[productId])
        .filter(product => product != null)
        .forEach(product => {
          const {childSKUs = [], id} = product;

          if (childSKUs?.length) {
            skuIds.push(...childSKUs);
            productIds.push(id);
          }
        });

      if (skuIds.length) {
        setSkuIds(skuIds);
        setProductIds(productIds);
      }
    }
  }, [products, recommendations]);

  const fetchSkusPrices = useCallback(
    async stringified => {
      setLastSkus(stringified);
      const filteredSkusPrices = skuIds.filter(skuId => isEmptyObject(skusPrices?.[skuId]));

      if (filteredSkusPrices.length) {
        await action('getSkusPrices', {skuIds: filteredSkusPrices}).catch(() => {
          setShowSkeleton(false);
        });
      }
    },
    [action, skuIds, skusPrices]
  );

  useEffect(() => {
    const stringified = skuIds.join(',');
    if (skuIds.length && stringified !== lastSkus) {
      fetchSkusPrices(stringified);
    }
  }, [fetchSkusPrices, lastSkus, skuIds]);

  useEffect(() => {
    if (isEmptyObject(skusPrices) || !productIds.length || !skuIds.length || isEmptyObject(skuInventoryItems)) return;

    const recommendationsSlides = [];
    const validSkuIds = [];
    const viewItemlist = [];

    for (let index = 0; index < productIds.length; index++) {
      const productId = productIds[index];
      const product = products[productId];
      const {childSKUs = []} = product || {};

      const skuId =
        childSKUs?.find(id => {
          if (!displayCTAButton) {
            return skus[id]?.ltd_ltdStockStatus !== '11' && skus[id]?.ltd_showInSearchSku !== 'No';
          }

          return (
            skus[id]?.ltd_ltdStockStatus !== '11' &&
            skus[id]?.ltd_showInSearchSku !== 'No' &&
            !skus[id]?.ltd_isDummySku &&
            skus[id]?.ltd_personalized === 'No'
          );
        }) || {};

      const skuDetails = skus?.[skuId] || {};

      const {ltd_personalized, repositoryId} = skuDetails || {};

      const {default: {stockStatus = OUT_OF_STOCK} = {}} = skuInventoryItems[repositoryId] || {};

      const skuPersonalized = ltd_personalized === 'Yes';
      if ((skuPersonalized && displayCTAButton) || stockStatus === OUT_OF_STOCK) {
        continue;
      }

      if (!isEmptyObject(skuDetails)) {
        const NO_IMAGE = '/img/no-image.jpg';
        let skuImage = NO_IMAGE;

        const urlSku = [];

        let pickersName = `${skuDetails?.ltd_pickerOneValue}`;
        if (skuDetails?.ltd_pickerTwoValue) {
          pickersName = `${pickersName}, ${skuDetails?.ltd_pickerTwoValue}`;
        }
        const fullName = product?.displayName;

        if (
          skuDetails?.fullImageURLs?.length &&
          !(
            product?.ltd_isSingleSku === 'Yes' ||
            product?.ltd_isDummySku ||
            fullName.toLowerCase() === pickersName.toLowerCase()
          )
        ) {
          skuDetails.fullImageURLs.forEach(url => {
            if (url !== NO_IMAGE && url?.includes('_zm.')) {
              urlSku.push(url);
            }
          });
        }

        if (urlSku.length > 0) {
          const [image] = urlSku;
          skuImage = image;
        } else {
          const productImagesUrl = [];

          if (product?.fullImageURLs?.length) {
            product.fullImageURLs.forEach(url => {
              if (url !== NO_IMAGE && url?.includes('_zm.')) {
                productImagesUrl.push(url);
              }
            });
          }

          if (productImagesUrl.length > 0) {
            const [image] = productImagesUrl;
            skuImage = image;
          }
        }

        const {listPrice = null, salePrice = null} = skuDetails || {};
        if (listPrice || salePrice) {
          viewItemlist.push({product, skuDetails});
          validSkuIds.push(skuId);
          recommendationsSlides.push(
            <SplideSlide key={index}>
              <ProductItem
                product={product}
                recSetId={recSetId}
                sku={skuId}
                displayCTAButton={displayCTAButton}
                skusToProdutItem={[skuDetails?.repositoryId]}
                skuDetails={skuDetails}
                skuImage={skuImage}
                isRecommendations={true}
                key={skuId}
                id={skuId}
                {...props}
              />
            </SplideSlide>
          );
        }
      }
    }
    
    if(validSkuIds?.join(",") !== skusInSlides?.join(",")){
      setSlides(recommendationsSlides);
      setSkusInSlides(validSkuIds);
      handleTealiumViewItemList(store, {viewItemlist}, trimmedHeadingProductRecommendations);
    }
    setShowSkeleton(false);  
  }, [skusPrices, productIds, skuIds, products, skus, recSetId, displayCTAButton, skuInventoryItems]);

  useEffect(() => {
    if (slides?.length) {
      const itemsPerPage = configItemsPerSlideDesktop || 5;

      const settings = {
        hasTrack: false,
        options: {
          autoplay: false,
          lazyLoad: true,
          speed: 400,
          type: ((slides.length > Number(itemsPerPage) || mobile && slides.length > 2) ? 'loop' : 'slide'),
          autoWidth: false,
          perPage: Number(itemsPerPage),
          breakpoints: {
            350: {
              perPage: 1
            },
            512: {
              perPage: 2,
            },
            768: {
              perPage: 3
            },
            912: {
              perPage: 3
            },
            1299: {
              perPage: 4
            },
            1300: {
              perPage: Number(itemsPerPage)
            }
          },
          perMove: 1,
          gap: mobile ? '8px' : '0px',
          start: 0
        }
      };

      setSlideSettings(settings);
    }
  }, [configItemsPerSlideDesktop, slides?.length, isMobile]);

  useEffect(() => {
    if (!isEmptyObject(slideSettings)) {
      setRefreshSlide(true);
    }
  }, [slideSettings?.options?.type]);

  useEffect(() => {
    if (refreshSlide) {
      setRefreshSlide(false);
    }
  }, [refreshSlide]);

  const carouselSkeleton = useMemo(() => { 
    const elementsArray = [];
    if (isClient) {
      for(let i = 0; i < 5; i++){
        elementsArray.push(<Skeleton key={i} className="LTDProductRecommendationsCarousel__SkeletonItem" />)
      }
    }
    return elementsArray;
  }, [isClient]);

  const slideNumberControl = slideSettings?.options?.perPage < 5;

  const PrevButtonSplide = () => {
    const goPrev = () => {
      splideRef?.current?.go('-${i}');
    };

    return (
      <button
        type="button"
        data-role="none"
        aria-label="Previous"
        className={`splide__arrow splide__arrow--prev slider-button slider-button-prev${
          (!mobile && slides?.length <= slideSettings?.options?.perPage) || (mobile && slides?.length <= 2)
            ? ' arrow-disabled'
            : ''
        }`}
        onClick={goPrev}
      >
        <span className="icon-arrow-large-left"></span>
      </button>
    );
  };

  const NextButtonSplide = () => {
    const goNext = () => {
      splideRef?.current?.go('+${i}');
    };

    return (
      <button
        type="button"
        data-role="none"
        aria-label="Next"
        className={`splide__arrow splide__arrow--next slider-button slider-button-next${
          (!mobile && slides?.length <= slideSettings?.options?.perPage) || (mobile && slides?.length <= 2)
            ? ' arrow-disabled'
            : ''
        }`}
        onClick={goNext}
      >
        <span className="icon-arrow-large-right"></span>
      </button>
    )
  }

  const recommendationsCarousel = useMemo(() => {

    if (isClient && (showSkeleton || refreshSlide ||  !displayWidget)) {
      return (
        <Styled id="LTDProductRecommendationsCarousel" css={css}>
          {trimmedHeadingProductRecommendations && (
            <Box className="ltd-ui-box ProductRecommendationsCarousel__Heading">
              <Skeleton width="180px" height="34px" />
            </Box>
          )}
          <div className="LTDProductRecommendationsCarousel__PDP__Skeleton">
            {carouselSkeleton}
          </div>
        </Styled>
      )
    }
  
    if (skusInSlides.length === 0 || isEmptyObject(slideSettings)) {
      return null;
    }

    return (
      <Styled id="LTDProductRecommendationsCarousel" css={css}>
        {displayWidget && recommendations && recommendations.length > 0 && (
          <div
            className={`LTDProductRecommendationsCarousel LTDProductRecommendationsCarousel__PDP ${
              slideNumberControl ? 'LTDProductRecommendationsCarousel__PDPFourSlides' : ''
            }`}
          >
            {trimmedHeadingProductRecommendations && (
              <Box className="ltd-ui-box ProductRecommendationsCarousel__Heading">
                {trimmedHeadingProductRecommendations}
              </Box>
            )}
            {skusInSlides.length === 0 ? (
              <></>
            ) : (
              <Flex className="ltd-ui-flex LTDProductRecommendationsCarousel__Flex">
                <PrevButtonSplide/>
                <Splide {...slideSettings} className={(displayCTAButton ? "ProductRecommendationsCard CardsWithCTA" : "ProductRecommendationsCard")} ref={splideRef}>              
                  <SplideTrack>
                    {slides}
                  </SplideTrack>
                </Splide>
                <NextButtonSplide/>
              </Flex>            
            )}
          </div>
        )}
      </Styled>
    );

  }, 
  [
    showSkeleton,
    displayWidget,
    refreshSlide,
    skusInSlides?.join(','),
    isClient
  ])

  return (
    <div ref={elementRef} className="LTDProductRecommendationsCarousel__PDP">
      {recommendationsCarousel}
    </div>
  );
  
};

LTDProductRecommendationsCarousel.propTypes = {
  widgetId: PropTypes.string.isRequired,
  headingProductRecommendations: PropTypes.string.isRequired
};

export default connect(getComponentData)(LTDProductRecommendationsCarousel);
